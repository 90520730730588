<template>
  <form-wizard
    id="quick-pay-wizard"
    @on-change="changeTab"
    shape="tab"
    color="#6c757d"
  >
    <template #title>
      &nbsp;
    </template>
    <template slot="footer" slot-scope="props">
      <hr>
      <div class="wizard-footer-left">
        <wizard-button
          v-if="props.activeTabIndex > 1 && !props.isLastStep"
          class="btn btn-primary"
          @click.native="props.prevTab();"
        >
          Indietro
        </wizard-button>
      </div>
      <div class="wizard-footer-right">
        <b-button
          variant="danger"
          @click="abortReport"
          v-if="!props.isLastStep"
          class="wizard-btn me-2"
        >
          Annulla
        </b-button>
        <wizard-button
          v-if="!props.isLastStep && props.activeTabIndex === 0"
          @click.native="props.nextTab()"
          class="btn btn-warning"
        >
          Prosegui
        </wizard-button>
        <wizard-button
          v-if="!props.isLastStep && props.activeTabIndex === 1 && hasFund"
          @click.native="props.nextTab()"
          :before-change="validatePayment"
          class="btn btn-warning"
        >
          Invia richiesta
        </wizard-button>
        <wizard-button
          v-if="props.isLastStep"
          @click.native="onComplete"
          class="btn btn-success"
        >
          Chiudi
        </wizard-button>
      </div>
    </template>
    <tab-content
      title="Avviso"
      icon="fas fa-hand-paper"
      class="content-invest"
    >
      <template #title>aaaa</template>
      <p>
        Centrale Rischi Pagamenti offre vari servizi, incluso il servizio di valutazione del merito
        creditizio di persone fisiche e imprese.
      </p>
      <p>
        Possono essere forniti report per qualsiasi persona fisica residente in Italia e per
        qualsiasi impresa con sede legale in Italia, anche qualora non risultino iscritte a CRPagamenti.
      </p>
      <p>
        Per le persone fisiche che non sono presenti nel Club dei Virtuosi Centrale Rischi
        Pagamenti produrrà un report redatto esclusivamente in base a informazioni esterne a CRPagamenti.
        Viceversa, per le persone fisiche presenti nel Club dei Virtuosi, il report conterrà sia
        informazioni esterne che dati interni a CRPagamenti, come il numero e il dettaglio delle
        segnalazioni a carico della persona fisica.
      </p>
      <p>
        I report su persone fisiche e imprese contengono lo Scoring CRP del soggetto valutato.
        Lo Scoring CRP rappresenta un’opinione di Centrale Rischi Pagamenti riguardo al merito
        creditizio del soggetto valutato, inteso come la capacità di quel soggetto di onorare
        puntualmente le proprie obbligazioni pecuniarie nell’arco dei 12 mesi seguenti alla
        generazione del report. Lo Scoring CRP è elaborato sulla base di dati e informazioni
        raccolte direttamente od indirettamente da CRP.
      </p>
      <p>
        Tutti i servizi offerti da Centrale Rischi Pagamenti sono regolati dai Termini e
        Condizioni del Portale. Ricorda che i report e gli altri documenti generati da CRPagamenti
        sono strettamente riservati all’utente che ne ha fatto richiesta e ne è consentita la
        divulgazione a terzi solo previo ottenimento del consenso esplicito di Centrale Rischi Pagamenti.
      </p>
    </tab-content>
    <tab-content
      title="Pagamento"
      icon="fas fa-euro-sign"
    >
      <payment-section :request-price="requestPrice" :has-funds="hasFund"/>
      <div v-if="!hasFund">
           Il credito residuo è insufficiente per poter effettuare l'acquisto del prodotto selezionato
      </div>
    </tab-content>
    <tab-content
      title="Inviata"
      icon="fas fa-paper-plane"
    >
      <analysis-request-send
        :submitted="submitted"
        :completed="completed"
        :has-error="hasError"
        :report-name="reportName"
      />
    </tab-content>
  </form-wizard>
</template>

<script>
import { debounce } from 'lodash';
import {
  isNotEmpty,
} from '@/utils/validators';
import { searchCompany, searchPerson } from '@/utils/selectSearch';
import { USER_ANALYSIS_CLASS } from '@/utils/interfaces';

const AnalysisRequestSend = () => import('@/components/wizards/userAnalysisSections/AnalysisRequestSend.vue');
const PaymentSection = () => import('@/components/wizards/userAnalysisSections/PaymentSection.vue');

export default {
  name: 'QuickPayWizard',
  components: {
    AnalysisRequestSend,
    PaymentSection,
  },
  props: {
    id: String,
    requestBought: { ...USER_ANALYSIS_CLASS },
    modalId: String,
  },
  data() {
    return {
      submitted: false,
      completed: false,
      hasError: false,
      reportCreated: null,
      options: [],
      paymentFailed: false,
    };
  },
  computed: {
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    activityStatus() {
      if (this.isCompanyProfile) {
        return this.$store.getters['subject/activityCompanyStatus'];
      }
      return this.$store.getters['subject/activityPersonStatus'];
    },
    isOperative() {
      return this.activityStatus.operative;
    },
    reportId() {
      return this.requestBought.analysisId || '';
    },
    baseButtonClasses() {
      return 'btn-wizard--large';
    },
    reportName() {
      return this.requestBought.requestName || '';
    },
    requestPrice() {
      return this.$store.getters['wallet/getPricing'](this.requestBought.requestType, this.requestBought.requestProduct)?.price;
    },
    isModal() {
      return isNotEmpty(this.modalId);
    },
    hasFund() {
      console.log('check fund');

      const myWallet = this.$store.getters['wallet/getWallet'] || { currency: 'CRP', amount: 0 };
      console.debug('my wallet', myWallet);
      return (myWallet.amount.amount - this.requestPrice.amount) >= 0;
    },
  },
  mounted() {
    if (!this.isOperative) this.$router.replace('/');
    this.$store.dispatch('wallet/loadPricingProduct');
  },
  methods: {
    onComplete() {
      // this.$router.replace('user-analysis-history');
      this.$bvModal.hide(this.modalId);
    },
    abortReport() {
      this.$confirm(
        {
          message: 'Sei sicuro di voler annullare la richiesta?',
          button: {
            no: 'No',
            yes: 'Si',
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: (confirm) => {
            if (confirm) {
              if (isNotEmpty(this.modalId)) {
                this.$bvModal.hide(this.modalId);
              } else {
                this.$router.replace('dashboard-utente');
              }
            }
          },
        },
      );
    },

    validatePayment() {
      console.log('validate payment');
      return this.hasFund;
    },
    changeTab(oldIndex, newIndex) {
      console.log('newIndex', oldIndex, newIndex);
      // if (newIndex === 2) {
      //
      // }
      if (newIndex === 2) {
        const {
          analysisId, referentInfo, requestType, requestProduct, ownerRequestInfo, labelRequestProduct,
        } = this.requestBought;
        let subjectDesc;
        let ownerRequestType;
        let ownerRequestId;
        if (ownerRequestInfo.companyId) {
          subjectDesc = ownerRequestInfo.companyName;
          ownerRequestType = 'company';
          ownerRequestId = ownerRequestInfo.companyId;
        } else {
          subjectDesc = `${ownerRequestInfo.name} ${ownerRequestInfo.surname}`;
          ownerRequestType = 'person';
          ownerRequestId = ownerRequestInfo.personId;
        }

        if (referentInfo.companyId) {
          subjectDesc = referentInfo.companyName;
        } else {
          subjectDesc = `${referentInfo.name} ${referentInfo.surname}`;
        }

        const description = `Completa pagamento prodotto ${labelRequestProduct} per ${subjectDesc}`;
        this.completed = false;

        this.submitted = true;

        this.$store.dispatch('wallet/createTransaction', {
          entityType: ownerRequestType,
          entityId: ownerRequestId,
          description,
          productType: requestType,
          productCode: requestProduct,
          paymentFor: {
            entityClass: 'org.trendframework.database.entity.SubjectAnalysisRequest',
            entityId: analysisId,
          },
        }).then((txCreated) => {
          console.log('txCreated', txCreated);
          this.$store.dispatch('wallet/loadWallet', { entityType: ownerRequestType.toLowerCase(), entityId: ownerRequestId });
          this.completed = true;
          this.submitted = false;
        }).catch((errorTx) => {
          console.log('errorTx', errorTx);
          this.paymentFailed = true;
          this.completed = true;
          this.submitted = false;
        });
      }
    },
    createNew() {
      this.currentSelection = {};
      this.addNewSubject = true;
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: debounce((loading, search, vm) => {
      let request = null;
      const { requestType, requestProduct } = vm.requestBought;

      const products = vm.$store.getters['tableUtils/getTableList'](requestType) || [];

      const product = products.filter((item) => item.key === requestProduct);
      const customFilter = {};
      if (product.length > 0) {
        const { additionalInfos } = product[0];
        const { connectedCompany } = additionalInfos;
        customFilter.connectedCompany = connectedCompany;
      }
      if (vm.isCompany) {
        request = searchCompany(search, customFilter);
      } else if (vm.isPerson) {
        request = searchPerson(search);
      }
      if (!request) {
        // eslint-disable-next-line no-param-reassign
        vm.options = [];
        return;
      }
      request.then((res) => {
        console.log('res', res);
        // eslint-disable-next-line no-param-reassign
        vm.options = res;
        loading(false);
      }).catch((err) => {
        console.log('error search ', requestProduct, err);
        loading(false);
      });
    }, 350),
  },
};
</script>

<style scoped>

</style>
